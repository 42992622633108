<template>
    
    <payment 
        ref="payment"
        @pending="pending"   
        @register_error="registerError"
    >
        <form id="billing-form" action="" method="post">
            <payment-form 
                :internalErrors="errors"
                @submitForm="submitForm"
            >
            </payment-form>
        </form>    
    </payment>
</template>

<script>

import PaymentForm from '../../forms/PaymentForm';
import Payment from '../PaymentContainer';

export default {
    components: {
        PaymentForm, Payment
    }, 
    data() {
        return {
            isProcessing: false,
            currencyCode: null,
            rate: null,
            usualRate: null,
            planType: null,
            errors: [],
            init_client: null,
            rebill_client: null,
            count: 0,
            apiKey: null,
            endpoint: null,
            amount: 0,
            data: {},
        }
    },
    computed: {
        currency() {
            return this.$store.currency;
        },
        usualAmount(){  
            return this.$payment.plan_usual.amount;
        },
    },
    mounted(){
        let threeDSScript = document.createElement('script')
        threeDSScript.setAttribute('src', '/js/threeds.js');
        document.body.appendChild(threeDSScript);
        this.getPaymentData(); 
    },
    methods: {
        registerError(data){
            this.errors = data;    
        },

        submitForm(data){
            this.data = data;
            this.init_client = this.initClient();
            this.rebill_client = this.initClient();

            this.init_client.verify(
                this.completedForm,
                this.failedForm,
                {amount: this.amount}
            );
            this.rebill_client.verify(
                this.completedForm,
                this.failedForm,
                {amount: this.usualAmount}
            );
        },

        pending(data){
            this.iframeData = data.data;
        },
        
        initClient() {
            return new ThreeDS(
                "billing-form",
                this.apiKey,
                null, {
                    endpoint: this.endpoint,
                    currency: this.currencyCode.toString(),
                    verbose: false,
                    forcedTimeout: 30,
                    challengeWindowSize: '03',
                    showChallenge:false,
                    autoSubmit: false
            });
        },

        completedForm(response) {
            if (response.status === 'N' || response.status === 'U'|| response.status === 'A') {
                this.$bvModal.msgBoxOk('Payment failed')
                this.$loady(false)
                return;
            }

            if (response.status === 'C') {
                this.$bvModal.msgBoxOk('Payment failed')
                this.$loady(false)
                return;
            }

            this.count++;

            if (this.count === 1) {
                this.data.eci = response.eci;
                this.data.cavv = response.authenticationValue;
                this.data.xid = response.dsTransId;
            }
            if (this.count === 2) {
                this.data.rebill_eci = response.eci;
                this.data.rebill_cavv = response.authenticationValue;
                this.data.rebill_xid = response.dsTransId;
                this.$refs.payment.processPayment(this.data);
            }
        },

        failedForm(response, status) {
            if (status === 404) return;
            const message = JSON.parse(response)
            this.$bvModal.msgBoxOk(message.error)
            this.isProcessing = false
        },

        async getPaymentData() {
            await axios.get('/user/get-konnektive/' + this.currency)
                .then((res) => {
                    this.currencyCode = res.data.data.currency_code;
                    this.apiKey = res.data.data.api_key;
                    this.endpoint = res.data.data.endpoint;
                    this.amount = res.data.data.plan.amount;
                    this.$forceUpdate();
                });
        },
    }
}
</script>